var themes = [
  "Out of Time",
  "Reverse Engineering",
  "Parallel Universe",
  "Lost in Translation",
  "Infinite Loop",
  "Hidden Path",
  "Mind Control",
  "Dimensional Shift",
  "Cybernetic Enhancement",
  "Underwater Exploration",
  "Haunted Mansion",
  "Time Warp",
  "Steampunk Adventure",
  "Alien Invasion",
  "Survival Horror",
  "Mystical Artifact",
  "Space Travel",
  "Robotic Uprising",
  "Mythical Creatures",
  "Apocalyptic Wasteland",
  "Virtual Reality",
  "Secret Agent Mission",
  "Medieval Quest",
  "Pixelated World",
  "Future Urban Jungle",
  "Mad Scientist Experiment",
  "Magical Forest",
  "Pirate Treasure Hunt",
  "Galactic War",
  "Cursed Amulet",
  "Cyberpunk City",
  "Jungle Safari",
  "Grim Reaper's Deal",
  "Dystopian Society",
  "Time Travel Paradox",
  "Mysterious Island",
  "Castle Siege",
  "Superhero Origins",
  "Futuristic Sports",
  "Zombie Outbreak",
  "Alien Abduction",
  "Ninja Assassin",
  "Fantasy Kingdom",
  "Robot Rebellion",
  "Surreal Dreamscape",
  "Space Station Disaster",
  "Cybernetic Gladiators",
  "Underground Revolution",
  "Secret Laboratory",
  "Sorcerer's Apprentice",
  "Jurassic Park",
  "Witch's Curse",
  "Galactic Explorers",
  "Urban Legend",
  "Mecha Mayhem",
  "Dark Fantasy",
  "Wild West Showdown",
  "Time-Looping Detectives",
  "Alien Artifact",
  "Haunted Carnival",
  "Robotic Apocalypse",
  "Fairy Tale Remix",
  "Aerial Dogfight",
  "Lunar Colony",
  "Viking Raid",
  "Spy Espionage",
  "Mythical Beasts",
  "Western Outlaw",
  "Cybernetic Samurai",
  "Time-Traveling Pirates",
  "Midnight Masquerade",
  "Cursed Temple",
  "Stealth Mission",
  "Ghost Ship",
  "Pixel Power",
  "Mythological Titans",
  "Future Noir",
  "Space Oddity",
  "Magnetic Anomaly",
  "Dark Carnival",
  "Enchanted Labyrinth",
  "Desert Mirage",
  "Subterranean Odyssey",
  "Lost City of Eldorado",
  "Steam-powered Inventions",
  "Tropical Paradise Lost",
  "Dreamscape Dilemma",
  "Futuristic Dystopia",
  "Mythical Mischief",
  "Stars Aligning",
  "Civilization Reborn",
  "Cyberspace Conundrum",
  "Forest of Whispers",
  "Airship Adventures",
  "Pandora's Box",
  "Arcane Alchemy",
  "Neon City Nights",
  "Lunar Legend",
  "City of Ember",
  "Mad Max Mayhem",
  "Guardian of Gaia",
  "Pulse of the Cosmos",
  // 100 new themes start here
  "Echoes of Eternity",
  "Spectral Symphony",
  "Chaos Theory",
  "Techno Wizardry",
  "Phantom Menace",
  "Ethereal Enchantment",
  "Cataclysmic Collision",
  "Mind's Eye Mirage",
  "Quantum Quandary",
  "Celestial Serenade",
  "Rogue Renegades",
  "Echo Chamber",
  "Chrono Chaos",
  "Mechanical Monarchy",
  "Stellar Surfer",
  "Eclipse Encounter",
  "Temporal Tango",
  "Cosmic Crusade",
  "Zero Gravity Zenith",
  "Nebula Nexus",
  "Orbital Odyssey",
  "Synthetic Symphony",
  "Interstellar Intrigue",
  "Paradoxical Paradigm",
  "Binary Ballet",
  "Astral Ascendance",
  "Infinity's Edge",
  "Galactic Gambit",
  "Nuclear Noir",
  "Vortex Voyage",
  "Fractal Frenzy",
  "Quantum Quest",
  "Cosmic Carnival",
  "Nanotech Nirvana",
  "Dimensional Dance",
  "Cyber Serenity",
  "Neural Nostalgia",
  "Chronicle Conundrum",
  "Holographic Harmony",
  "Sublime Synthesis",
  "Stellar Synapse",
  "Aurora Awakening",
  "Nebulous Nemesis",
  "Echoes of Eden",
  "Eternal Echo",
  "Quantum Quasar",
  "Stellar Sonata",
  "Techno Tesseract",
  "Chronicle Cipher",
  "Nebula Nucleus",
  "Astral Alchemy",
  "Celestial Cipher",
  "Ethereal Exodus",
  "Binary Bliss",
  "Paradox Paragon",
  "Quantum Quandary",
  "Synthetic Symphony",
  "Orbital Overture",
  "Fractal Fantasy",
  "Stellar Serenity",
  "Nanotech Nirvana",
  "Dimensional Duet",
  "Cybernetic Sonata",
  "Chronicle of Chaos",
  "Holographic Harmony",
  "Sublime Symphony",
  "Stellar Synthesis",
  "Aurora Ascendance",
  "Nebula Nexus",
  "Echoes of Eden",
  "Eternal Echo",
  "Quantum Quasar",
  "Stellar Sonata",
  "Techno Tesseract",
  "Chronicle Cipher",
  "Nebula Nucleus",
  "Astral Alchemy",
  "Celestial Cipher",
  "Ethereal Exodus",
  "Binary Bliss",
  "Paradox Paragon",
  "Quantum Quandary",
  "Synthetic Symphony",
  "Orbital Overture",
  "Fractal Fantasy",
  "Stellar Serenity",
  "Nanotech Nirvana",
  "Dimensional Duet",
  "Cybernetic Sonata",
  "Chronicle of Chaos",
  "Holographic Harmony",
  "Sublime Symphony",
  "Stellar Synthesis",
  "Aurora Ascendance",
  "Nebula Nexus"
];


export default themes;