import './App.css';
import GameJamGenerator from './Components/GameJam';
import logo from "./logos/rockmonk-logo-2.png";

function App() {
  return (
    <div className="container p-4">
      <div className='row my-4 justify-content-center'>
        <img src={logo} className='company-logo' />
      </div>
      <GameJamGenerator />
    </div>
  );
}

export default App;
