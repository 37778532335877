import React, { useState, useEffect } from 'react';

import themes from '../Data/themes';

const GameJamGenerator = () => {
  const [currentTheme, setCurrentTheme] = useState('');
  const [generatedThemes, setGeneratedThemes] = useState([]);

  useEffect(() => {
      const storedThemes = JSON.parse(localStorage.getItem('generatedThemes'));
      if (storedThemes) {
          setGeneratedThemes(storedThemes);
      }
  }, []); 

  const generateRandomTheme = () => {
      let newTheme = themes[Math.floor(Math.random() * themes.length)];
      
      while (generatedThemes.includes(newTheme)) {
          newTheme = themes[Math.floor(Math.random() * themes.length)];
      }

      const updatedThemes = [...generatedThemes, newTheme];
      setGeneratedThemes(updatedThemes);
      localStorage.setItem('generatedThemes', JSON.stringify(updatedThemes));

      setCurrentTheme(newTheme);
  }

  const clearLocalStorage = () => {
      setGeneratedThemes([]);
      localStorage.removeItem('generatedThemes');
  }

  const generate100RandomThemes = () => {
      let newThemesArray = [...themes];
      
      for (let i = themes.length; i < themes.length + 100; i++) {
          newThemesArray.push(`Random Theme ${i + 1}`);
      }

      themes = newThemesArray;
  }

  return (
      <div className='row justify-content-md-center'>
          
          <div className='custom-well mb-4'>
            <h4>Current Theme: {currentTheme}</h4>
          </div>
          
          <div className='col-md-auto'>
            <button className="btn btn-primary btn-sm my-2 mx-2" onClick={generateRandomTheme}>Create Theme</button>
            <button className="btn btn-danger btn-sm my-2" onClick={clearLocalStorage}>Clear Local Storage</button>
          </div>

          <div className='mt-4 custom-well custom-well-dark'>
            <h4>Previous Themes:</h4>
            <ul>
                {generatedThemes.map((theme, index) => (
                    <li key={index}>
                      <h5> {theme} </h5>
                    </li>
                ))}
            </ul>
          </div>      
        </div>
  );
};

export default GameJamGenerator;